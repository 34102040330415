import React from "react";
import Header from "../components/common/header";
import "../styles/home.css";
import Hero from "../components/home/hero";
import OurServices from "../components/home/ourServices";
import Cars from "../components/home/cars";
import Footer from "../components/common/footer";
import WhyChoose from "../components/home/whychoose";
import Loading from "../components/loading/Loading";
import { useState, useEffect } from "react";

const Home = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Header />
          <Hero />
          <Cars />
          <WhyChoose className="margin-for-launch" />
          <OurServices />
          <Footer />
        </>
      )}
    </div>
  );
};

export default Home;
