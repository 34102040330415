import React from "react";
import loadingImg from "../../assets/loading/loading.png";
import "../../styles/loading/loading.css";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";

const Loading = () => {
  return (
    <div className="loading-image-container">
      {/* <motion.div
        animate={{
          rotate: 360,
          transition: { duration: 1, repeat: Infinity, ease: "linear" },
        }}
      >
        <img src={loadingImg} alt="loading..." className="loading-image" />
      </motion.div> */}
      <div id="load">
        <div className="main__heading">G</div>
        <div className="main__heading">N</div>
        <div className="main__heading">I</div>
        <div className="main__heading">D</div>
        <div className="main__heading">A</div>
        <div className="main__heading">O</div>
        <div className="main__heading">L</div>
      </div>
    </div>
  );
};

export default Loading;
