import React, { useEffect } from "react";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/home.css";
import { motion } from "framer-motion";

const BookingFormCat = ({ open, close, car }) => {
  const form = useRef();

  if (!open) return null;

  // Send email
  const sendEmail = (e) => {
    e.preventDefault();
    try {
      emailjs
        .sendForm("service_s2e2l1e", "template_xlmncw9", form.current, {
          publicKey: "dz364DJ-_-jYbSWiF",
        })
        .then(
          () => {
            console.log("SUCCESS!");
            toast.success("Booking Confirmed", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <motion.div
        initial={{ opacity: 0, scale: 0.98 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.25 }}
        className="booking-form__overlay"
      >
        <form ref={form}>
          <div className="booking__form ">
            <h4>Book Your {car} Now</h4>
            <div className="booking__form__input__fields">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="name" />
            </div>
            <div className="booking__form__input__fields">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="email" />
            </div>
            <div className="booking__form__input__fields">
              <label htmlFor="ph_1">Phone Number</label>
              <input type="phone" required id="ph_1" name="phone_number_1" />
            </div>
            <div className="booking__form__input__fields">
              <label htmlFor="ph_2">Phone Number</label>
              <input type="phone" id="ph_2" name="phone_number_2" />
            </div>
            <input hidden type="date" id="city" name="car" value={car} />

            <div className="booking__form__input__fields">
              <label htmlFor="city">City</label>
              <input type="text" id="city" name="city" />
            </div>
            <div className="booking__form__input__fields">
              <label htmlFor="city">Pickup Date</label>
              <input type="date" id="city" name="from_date" />
            </div>
            <div className="booking__form__input__fields">
              <label htmlFor="city">Drop Date</label>
              <input type="date" id="city" name="to_date" />
            </div>

            <div className="booking__form__button__container">
              <button className="button__close" onClick={close}>
                Close
              </button>
              <button
                type="submit"
                className="button__book"
                onClick={sendEmail}
              >
                Book Now
              </button>
            </div>
          </div>
        </form>
      </motion.div>
      <ToastContainer />
    </div>
  );
};

export default BookingFormCat;
